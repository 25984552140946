import React from "react"
import Rating from '@mui/material/Rating';
import { Rate } from 'antd';
import { Col, Divider, Row } from "antd";

type Props = {
    title: string;
    score: number;
    divider?: boolean;
    star?: boolean;
    simple: boolean;
}

export const Score = ({title, score, divider = true, star = true, simple}: Props) => {
    return (
        <Row className={simple ? "score-container-simple" : "score-container"}>
            <Col span={20}>
                <p className="score-title">{title}</p>
                <p><span className="score">{score}</span><span className="score-base">/10</span></p>
                {(star && !simple) && <p className="score-rating"><Rate disabled value={score / 2.0} allowHalf /></p>}
            </Col>            
            {
                divider &&
                <Col span={1}>
                    <Row className="score-divider-wrapper" justify="center">
                        <Col>
                            <Divider type="vertical" className="score-divider" />   
                        </Col>
                    </Row>
                </Col>
            }
        </Row>
    )
}