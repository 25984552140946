import React, { useState, ReactNode } from 'react';
import { Select, SelectProps } from "antd";
import styled from "styled-components";
import mobile from "is-mobile";
import { SearchPage } from "./SearchPage";
import { Person, Topic } from './types';


type Props = SelectProps & {
  prefixIcon?: ReactNode;
  searchPage?: boolean;
  title?: string;
  subtitle?: string;
  items?: Person[] | Topic[];
  itemOnClick?: (item: any) => void;
  searchPrefix?: ReactNode;
};

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  background: rgba(8, 8, 17, 0.95);
  height: 60px;
  width: min(90vw, 450px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: ${mobile() ? "2%;box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2)" : "0%"};


  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .suffix-icon {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    right: 0;
    justify-content: center;
    pointer-events:none
  }

  .ant-select-item-option-selected {
    color: red;
  }

  && .ant-select .ant-select-selector {
    padding-left: ${mobile() ? "0rem" : "calc(3rem)"};
  }

  && .ant-select .ant-select-selector .ant-select-selection-search input {
    padding-left: calc(3rem - 9px);
  }

  && .ant-select .ant-select-selector .ant-select-selection-item {
    height: 60px;
    color: lightblue;
    font-size: 1rem;
    vertical-align: middle;
    line-height: 60px;
  }  
`;


export const IconSelect = ({ prefixIcon, children, searchPrefix, title = "", searchPage = true, value, subtitle = "", itemOnClick = () => {}, items = [], ...rest}: Props) => {
  const [showSearchPage, setShowSearchPage] = useState<boolean>(false);
  if (showSearchPage) {
    return <SearchPage itemOnClick={(item) => {
      setShowSearchPage(false);
      itemOnClick(item);
    }} items={items} onBack={() => setShowSearchPage(false)} placeholder={title} prefixIcon={searchPrefix} subtitle={subtitle}/>
  }
  if (mobile()) {
    return (
      <SelectWrapper className="select-wrapper">
        {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
        <Select placeholder={title} value={value} showSearch={false} {...rest} className='search-select' onClick={() => {
          if (searchPage) {
            setShowSearchPage(true);
          }
        }}>
          {children}
        </Select>
      </SelectWrapper>
    )
  }
  return (
    <SelectWrapper className="select-wrapper">
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <div className='suffix-icon'><img src="dropdown.png" width={12} alt=""/></div>
      <Select value={value} showSearch {...rest} className='search-select' virtual>
        {children}
      </Select>
    </SelectWrapper>
  );
};