import React, { useEffect, useRef, useState } from 'react';
import { IconSelect } from "./IconSelect"
import { IconSelectSimple } from "./IconSelectSimple"
import { PersonTopic, Topic, Person, Summary } from './types';
import { PoliglimpseClient } from './client';
import { SelectOption } from "./SelectOption";
import { Button, Select, message } from 'antd';
import PersonIcon from '@mui/icons-material/Person';
import { CaretRightFilled } from '@ant-design/icons';
import { Details } from './Details';
import { Disclaimer } from './Disclaimer';
import "./App.css";

type Props = {
  simple: boolean;
}

export const App = ({simple}: Props) => {
  const topicsPrefix = (
    <span style={{color: "red", width: "3rem", fontSize: "1.5rem"}}>
      #
    </span>
  );

  const [topics, setTopics] = useState<Topic[]>([]);
  const [persons, setPersons] = useState<Person[]>([]);
  const [topicId, setTopicId] = useState<number | null>(null);
  const [personId, setPersonId] = useState<number | null>(null);
  const [personTopics, setPersonTopics] = useState<PersonTopic[]>([]);
  const [client, setClient] = useState<PoliglimpseClient>();
  const [messageApi, contextHolder] = message.useMessage();
  const [summary, setSummary] = useState<Summary | null>(null);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  useEffect(() => {
    setClient(new PoliglimpseClient());
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }

    const fetchData = async() => {
      setTopics(await client.genTopics());
      setPersons(await client.genPersons());
      setPersonTopics(await client.genPersonTopics());
    }

    fetchData();
  }, [client]);

  const info = () => {
    const person = persons.filter(person => person.id === personId)[0];
    const topic = topics.filter(topic => topic.id === topicId)[0];
    messageApi.info(
      <span><b>{person.name}</b> has no comments on topic <b>{topic.name}</b></span>
    );
  };

  const search = async () => {
    const topicSummary = await client!.genSummary(topicId!, personId!);
    if (topicSummary === null) {
      info();
      return;
    }
    setShowSearch(false);
    setSummary(topicSummary);
    console.log(topicSummary);
  };

  const allTopicIds = personTopics.map(personTopic => personTopic.topic_id);
  const availableTopics = topics.filter(topic => allTopicIds.includes(topic.id));
  const availablePersonIds = personTopics.filter(personTopic => !topicId || personTopic.topic_id === topicId).map(it => it.person_id);
  const personCandidates = persons.filter(person => availablePersonIds.includes(person.id));

  return (
    <div className="App">
      <header className={simple ? "App-header-simple" : "App-header"}>
        { summary && <Details simple={simple} person={persons.filter(person => person.id === personId)[0]} summary={summary} onBack={() => {
          setPersonId(null);
          setTopicId(null);
          setSummary(null);
          setShowSearch(true);
        }}/>}
        {showSearch &&
          <div className={simple ? "title-container-simple" : "title-container"}>
            {!simple && <img src='./logo512.png' alt='' width={256 * window.innerHeight / 1400.0} /> }
            <span className="app-title">PoliGlimpse</span>
            <span className='subtitle'>Debate Database</span>
          </div>
        }
        <div className='search-bar-container'>
          {contextHolder}
          {showSearch && 
            <>
            {simple ?
            <IconSelectSimple
            value={availableTopics.filter(topic => topic.id === topicId)[0]?.name || null}
            onChange={(value) => setTopicId(value)}
            title='Search Topics'
            placeholder="Select Topic ..."
            prefixIcon={simple ? null : topicsPrefix}
          >
          {
              availableTopics.map(option => 
              <Select.Option value={option.id}>
                <SelectOption selected={option.id === topicId} topic={option.name} />
              </Select.Option>
            )
          }
          </IconSelectSimple>
          :
          <IconSelect 
              onChange={(value) => {
                setPersonId(null);
                setTopicId(availableTopics.filter(topic => topic.name === value)[0].id);
              }}
              title='Search Topics'
              value={availableTopics.filter(topic => topic.id === topicId)[0]?.name || null}
              placeholder="Select Topic ..."
              prefixIcon={simple ? null : topicsPrefix}
            >
            {
              availableTopics.map(option => 
                <Select.Option value={option.name}>
                  <SelectOption selected={option.id === topicId} topic={option.name} />
                </Select.Option>
              )
            }
            </IconSelect>
            }
            {simple ? 
              <IconSelectSimple
                onChange={(value) => setPersonId(value)}
                title='Search Names' 
                value={persons.filter(person => person.id === personId)[0]?.name || null}
                placeholder="Select Name ..."
                prefixIcon={simple ? null : <PersonIcon color="primary" className='prefix-icon' />}
              >
                {
                  personCandidates.map(option => 
                    <Select.Option value={option.id}>
                      <SelectOption selected={option.id === personId} topic={option.name} />
                    </Select.Option>
                  )
                }
              </IconSelectSimple>
              :
              <IconSelect 
                onChange={(value) => setPersonId(persons.filter(person => person.name === value)[0].id)}
                value={persons.filter(person => person.id === personId)[0]?.name || null}
                title='Search Names' 
                placeholder="Select Name ..."
                prefixIcon={simple ? null : <PersonIcon color="primary" className='prefix-icon' />}
              >
                {
                  personCandidates.filter(person => person.name.length > 0).map(option => 
                    <Select.Option value={option.name}>
                      <SelectOption selected={option.id === personId} topic={option.name} />
                    </Select.Option>
                  )
                }
              </IconSelect>
            }
            <div>
              {personId && topicId &&
              <Button className="search-button" onClick={() => search()}>
                Click to View <CaretRightFilled />
              </Button>
              }
            </div>
          </>
        }
        </div> 
        {
          (!simple && showSearch) && 
          <div className="footer">
            Copyright © 2024 poliglimpse by Keven, All Rights Reserved.| <span onClick={() => {
              setShowDisclaimer(true);
              setShowSearch(false);
            }} className="disclaimer-link">Introduction & Disclaimer</span>
          </div>
        }
        <Disclaimer visible={showDisclaimer} onBack={() => {
          setPersonId(null);
          setTopicId(null);
          setShowSearch(true);
          setShowDisclaimer(false);
        }}/>
      </header>      
    </div>
  );
}