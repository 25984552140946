import React from "react";

type Props = {
  topic: string;
  selected: boolean;
}

export const SelectOption = ({topic, selected}: Props) => {
  return (
    <span className={selected ? "select-option-selected" : "select-option "}> {topic} </span>
  );
};