import React, { useState, ReactNode } from 'react';
import { Select, SelectProps } from "antd";
import styled from "styled-components";
import mobile from "is-mobile";
import { SearchPage } from "./SearchPage";
import { Person, Topic } from './types';


type Props = SelectProps & {
  prefixIcon?: ReactNode;
  searchPage?: boolean;
  title?: string;
  subtitle?: string;
  items?: Person[] | Topic[];
  itemOnClick?: (item: any) => void;
};

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  background: black;
  height: 60px;
  width: min(90vw, 450px);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;


  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(1rem);
  }

  && .ant-select .ant-select-selector .ant-select-selection-search input {
    padding-left: calc(0rem);
    color: white;
  }

  && .ant-select .ant-select-selector .ant-select-selection-item {
    color: white;
    font-size: 1rem;
    vertical-align: middle;
  }
`;


export const IconSelectSimple = ({ prefixIcon, children, title = "", searchPage = true, value, subtitle = "", itemOnClick = () => {}, items = [], ...rest}: Props) => {
  const [showSearchPage, setShowSearchPage] = useState<boolean>(false);
  if (showSearchPage) {
    return <SearchPage itemOnClick={(item) => {
      setShowSearchPage(false);
      itemOnClick(item);
    }} items={items} onBack={() => setShowSearchPage(false)} placeholder={title} prefixIcon={prefixIcon} subtitle={subtitle}/>
  }
  if (mobile()) {
    return (
      <SelectWrapper className="select-wrapper">
        {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
        <Select value={value} showSearch={false} {...rest} className='search-select' onClick={() => {
          if (searchPage) {
            setShowSearchPage(true);
          }
        }}>
          {children}
        </Select>
      </SelectWrapper>
    )
  }
  return (
    <SelectWrapper className="select-wrapper">
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select showSearch {...rest} className='search-select'>
        {children}
      </Select>
    </SelectWrapper>
  );
};