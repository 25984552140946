import React, { useEffect, useRef, useState } from 'react';
import { IconSelect } from "./IconSelect";
import { IconSelectSimple } from "./IconSelectSimple";
import { Topic, Person, Summary, PersonTopic } from './types';
import { PoliglimpseClient } from './client';
import { SelectOption } from "./SelectOption";
import { Button, Select, message } from 'antd';
import PersonIcon from '@mui/icons-material/Person';
import { CaretRightFilled } from '@ant-design/icons';
import { DetailsMobile } from './DetailsMobile';
import { DisclaimerMobile } from './DisclaimerMobile';
import "./AppMobile.css"

type Props = {
    simple: boolean;
}

export const AppMobile = ({simple}: Props) => {
  const topicsPrefix = (
    <span style={{color: "red", width: "3rem"}}>
      #
    </span>
  );

  const [topics, setTopics] = useState<Topic[]>([]);
  const [persons, setPersons] = useState<Person[]>([]);
  const [topicId, setTopicId] = useState<number | null>(null);
  const [personId, setPersonId] = useState<number | null>(null);
  const [client, setClient] = useState<PoliglimpseClient>();
  const [messageApi, contextHolder] = message.useMessage();
  const [summary, setSummary] = useState<Summary | null>(null);
  const [personTopics, setPersonTopics] = useState<PersonTopic[]>([]);
  const [showSearch, setShowSearch] = useState<boolean>(true);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  useEffect(() => {
    setClient(new PoliglimpseClient());
  }, []);

  useEffect(() => {
    if (!client) {
      return;
    }

    const fetchData = async() => {
      setTopics(await client.genTopics());
      setPersons(await client.genPersons());
      setPersonTopics(await client.genPersonTopics());
    }

    fetchData();
  }, [client]);

  const info = () => {
    const person = persons.filter(person => person.id === personId)[0];
    const topic = topics.filter(topic => topic.id === topicId)[0];
    messageApi.info(
      <span><b>{person.name}</b> has no comments on topic <b>{topic.name}</b></span>
    );
  };

  const windowWidth = useRef(window.innerWidth).current;
  const windowHeight = useRef(window.innerHeight).current;

  const logoSize = Math.min(windowHeight / 4, windowWidth / 3)

  const search = async () => {
    const topicSummary = await client!.genSummary(topicId!, personId!);
    if (topicSummary === null) {
      info();
      return;
    }
    setShowSearch(false);
    setSummary(topicSummary);
  };

  const allTopicIds = personTopics.map(personTopic => personTopic.topic_id);
  const availableTopics = topics.filter(topic => allTopicIds.includes(topic.id));
  const availablePersonIds = personTopics.filter(personTopic => !topicId || personTopic.topic_id === topicId).map(it => it.person_id);
  const personCandidates = persons.filter(person => availablePersonIds.includes(person.id));

  return (
    <div className="App">
      <header className={simple ? "App-header-simple" : "App-header-mobile"}>
        { summary && <DetailsMobile simple={simple} person={persons.filter(person => person.id === personId)[0]} summary={summary} onBack={() => {
          setTopicId(null);
          setPersonId(null);
          setSummary(null);
          setShowSearch(true);
        }}/>}
        {showSearch &&
          <div className={simple ? "title-container-simple" : "title-container"}>
            {!simple && <img src='./logo512.png' alt='' width={logoSize} /> }
            <span className="app-title-mobile">PoliGlimpse</span>
            <span className='subtitle-mobile'>Debate Database</span>
          </div>
        }
        <div className='search-bar-container-mobile'>
          {contextHolder}
          {showSearch && 
            <>
            {simple ?     
            <IconSelectSimple
              title='Search Topic ...'              
              subtitle="Topics"
              placeholder="Search Topic ..."
              value={topicId}
              prefixIcon={null}
              items={availableTopics}
              itemOnClick={(topic) => {
                setTopicId(topic.id);
                setPersonId(null);
                setShowSearch(true);                
              }}
            >
            {
              availableTopics.map(option => 
                <Select.Option value={option.id}>
                  <SelectOption selected={false} topic={option.name} />
                </Select.Option>
              )
            }
            </IconSelectSimple>                   
            :
            <IconSelect               
              title='Select Topic ...'              
              subtitle="Topics"
              value={topicId}
              prefixIcon={topicsPrefix}
              searchPrefix={<span> # </span>}
              items={availableTopics}
              itemOnClick={(topic) => {
                setTopicId(topic.id);
                setPersonId(null);
                setShowSearch(true);                
              }}
            >
            {
              availableTopics.map(option => 
                <Select.Option value={option.id}>
                  <SelectOption selected={false} topic={option.name} />
                </Select.Option>
              )
            }
            </IconSelect>
            }
            {simple ?
                <IconSelectSimple
                onChange={(value) => setPersonId(value)}              
                title="Select Name ..."
                subtitle="Names"
                placeholder="Select Name ..."
                value={personId}
                prefixIcon={null}
                itemOnClick={(person) => {
                    setPersonId(person.id);
                    setShowSearch(true);                
                }}
                items={personCandidates}
                >
                {
                    personCandidates.map(option => 
                    <Select.Option value={option.id}>
                        <SelectOption selected={false} topic={option.name} />
                    </Select.Option>
                    )
                }
                </IconSelectSimple>
            :
                <IconSelect 
                onChange={(value) => setPersonId(value)}              
                title="Select Name ..."
                subtitle="Names"
                value={personId}
                searchPrefix={<img src="person_prefix.jpg" width={14} alt=''/>}
                prefixIcon={<PersonIcon color="primary" className='prefix-icon' />}
                itemOnClick={(person) => {
                    setPersonId(person.id);
                    setShowSearch(true);                
                }}
                items={personCandidates}
                >
                {
                    personCandidates.map(option => 
                    <Select.Option value={option.id}>
                        <SelectOption selected={false} topic={option.name} />
                    </Select.Option>
                    )
                }
                </IconSelect>
            }
          </>
        }
        </div> 
        {
          showSearch && 
          <div className="footer">
            <div>
              {
                personId && topicId &&
                <Button className="search-button-mobile" onClick={() => search()} autoFocus={false}>
                  Click to View <CaretRightFilled />
                </Button>
              }
            </div>
            {!simple && 
                <span onClick={() => {
                setShowDisclaimer(true);
                setShowSearch(false);
                }} className="disclaimer-mobile-link">
                    Introduction & Disclaimer
                </span>
            }
          </div>
        }
        <DisclaimerMobile visible={showDisclaimer} onBack={() => {
          setTopicId(null);
          setPersonId(null);
          setShowSearch(true);
          setShowDisclaimer(false);
        }}/>
      </header>      
    </div>
  );
}
