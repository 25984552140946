import { Col, Row } from "antd";
import React from "react";

type Props = {
    content: string;
    mobile?: boolean;
}

export const HighLight = ({mobile = false, content}: Props) => {
    return (
        <div style={{backgroundColor: "rgba(17, 19, 33, 1)", padding: "10px"}}>
            <Row>
                <Col span={1}><img src="quote.png" width={mobile ? 16 : 24} alt=""/></Col>
                <Col span={23}><div className="highlight-content">{content}</div></Col>
            </Row>
        </div>
    )
}