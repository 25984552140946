import { Topic, Summary } from "../../backend/src/types";
import { Person, PersonTopic } from "./types";


export class PoliglimpseClient {
  async get<T> (url: string, parameters: URLSearchParams = new URLSearchParams()): Promise<T | string> {
    console.log("process.env.REACT_APP_BACKEND_URL", process.env.REACT_APP_BACKEND_URL);
    console.log("url", url);
    console.log("parameters", parameters);
    const requestUrl = `${process.env.REACT_APP_BACKEND_URL}/${url}?${parameters.toString()}`;
    console.log("requestUrl", requestUrl);
    const res = await fetch(requestUrl);

    if (!res.ok) {
      return await res.text();
    }
    return await res.json();
  }

  async genTopics(): Promise<Topic[]> {
    const res = await this.get("topics");
    if (typeof res === "string") {
      throw new Error(res);
    }

    return res as Topic[];
  }

  async genPersons(): Promise<Person[]> {
    const res = await this.get("persons");
    if (typeof res === "string") {
      throw new Error(res);
    }

    return res as Person[];
  }

  async genSummary(topic_id: number, person_id: number): Promise<Summary | null> {
    const searchParams = new URLSearchParams();
    searchParams.set("topic_id", `${topic_id}`);
    searchParams.set("person_id", `${person_id}`);
    const res = await this.get("personTopicSummary", searchParams);
    if (typeof res === "string") {
      throw new Error(res);
    }

    return res as Summary;
  }

  async genPersonTopics(): Promise<PersonTopic[]> {
    const searchParams = new URLSearchParams();
    const res = await this.get("personTopics", searchParams);

    return res as PersonTopic[];
  }

};