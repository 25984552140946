import { ArrowLeftOutlined } from "@ant-design/icons";
import { Row, Col, Avatar } from "antd";
import React from "react";
import { HighLight } from "./HighLight";
import { Score } from "./Score";
import './AppMobile.css';

type Props = {
    visible: boolean;
    onBack: () => void;
};

export const DisclaimerMobile = ({visible, onBack}: Props) => {
    if (!visible) {
        return null;
    }
    return (
        <div className="disclaimer-mobile-container">     
            <p className="back-mobile-button" onClick={() => onBack()}><ArrowLeftOutlined /> Home </p>   
            <h1 className="disclaimer-mobile-title">Introduction & Disclaimer</h1>
            <div>
                <h2 className="disclaimer-mobile-subtitle"> App Introduction </h2>
                <div className="disclaimer-mobile-content">
                    Welcome to our political analysis app, a cutting-edge platform designed to provide users with deep insights into political 
                    discourse and positions across a range of topics. Leveraging advanced AI technology, our app analyzes public political 
                    statements, debates, and discussions to quantify the stance and rhetoric of 
                    political figures and entities. Our goal is to empower users with a clearer understanding 
                    of political landscapes, enabling informed decision-making and engagement.    
                </div>                                
            </div>
            <div>
                <h2 className="disclaimer-mobile-subtitle"> Rating Dimensions Explained </h2>
                <div className="disclaimer-mobile-content">
                    Our app evaluates political discourse using the following dimensions, each scored from 0 to 10 for a nuanced assessment of political speech:
                    <ul className="disclaimer-mobile-list">
                        <li>
                            Sentiment (0-10): This score reflects the emotional tone of the statement, ranging from negative to positive. A higher score indicates a more positive sentiment. This helps users gauge the emotional appeal and tone of political discourse.
                        </li>  
                        <li>
                            Logic (0-10): This rating assesses the logical coherence and reasoning behind statements. A higher score signifies stronger, more rational argumentation. It's crucial for understanding the intellectual rigor and persuasiveness of the argument.
                        </li>
                        <li>
                            Consistency (0-10): This measure evaluates how consistent the political figure is in their statements on specific topics over time. A higher score indicates a higher level of consistency, suggesting reliability and steadfastness in political positions.
                        </li>
                        <li>
                            Objectiveness (0-10): This score gauges the level of objectivity in the discourse, with a higher score denoting a more fact-based and less biased approach. It's vital for assessing the impartiality and fairness of the statements.
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <h2 className="disclaimer-mobile-subtitle"> Summary and Highlight Features </h2>
                    <div className="disclaimer-mobile-content">
                        Our app evaluates political discourse using the following dimensions, each scored from 0 to 10 for a nuanced assessment of political speech:
                        <ul className="disclaimer-mobile-list">
                            <li>
                                Summary: This feature provides a concise overview of a political figure’s viewpoint on a particular topic, distilling their stance into an easily digestible format. It synthesizes the essence of their position, enabling users to quickly grasp the core message without delving into the entire discourse.
                            </li>  
                            <li>
                                Highlight: Showcases key sentences or statements that define the political figure’s discourse on a topic, capturing the most impactful or memorable phrases. This feature brings attention to pivotal moments in the narrative, helping users identify significant insights and rhetorical strategies.
                            </li>                                        
                        </ul>
                    </div>                                    
                </div>                            
            </div>
            <div>
                <div>
                    <h2 className="disclaimer-mobile-subtitle"> Disclaimer </h2>
                    <div className="disclaimer-mobile-content">
                        Please note that the analysis provided by this app is generated through AI algorithms based on available public political statements and discourse. While we strive for accuracy and objectivity in our analysis, the interpretations and scores should be considered as estimations and not definitive judgments. The nature of AI-generated analysis means that there may be limitations in understanding context, subtlety, and the full complexity of human language and political nuance. Users are encouraged to use the app's insights as a guide but also engage critically and consult multiple sources when forming opinions on political matters.
                    </div>                                    
                </div>                            
            </div>                            
        </div>
    );
};