import React, { ReactNode, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Divider, Input } from "antd";
import { Person, Topic } from './types';

type Props = {
    onBack: () => void;
    prefixIcon: ReactNode;
    placeholder: string;
    subtitle: string;
    items?: Person[] | Topic[];
    itemOnClick: ((item: any) => void) | undefined;
}

export const SearchPage = ({onBack, placeholder, prefixIcon, subtitle, items = [], itemOnClick = () => {}}: Props) => {    
    const [searchValue, setSearchValue] = useState<string>("");
    const filteredItems = (items as Person[]).filter(person => person.name.startsWith(searchValue));

    const renderPersons = (prefixIcon: ReactNode, persons: Person[], ) => {    
        return persons.map(person => (
            <div onClick={() => itemOnClick(person)} className="mobile-search-page-item"> {prefixIcon} &nbsp;&nbsp; {person.name} </div>
        ));
    }

    return (
        <div className="search-page">
            <p className="back-mobile-button" onClick={() => onBack()}><div><img src="mobile-back.png" alt="" width={24}/></div> <span> Home </span></p>   
            <Input 
                size="large" 
                onChange={(event) => setSearchValue(event.target.value)} 
                value={searchValue} 
                placeholder={placeholder}
                prefix={<div className="prefix-icon-wrapper">{prefixIcon} <Divider className="search-divider" type="vertical"/> </div>} /> 
            <div className="mobile-search-divider"/>
            <div className="search-page-options">
                <h2 className="search-page-subtitle"> {subtitle} </h2>                
                {renderPersons(prefixIcon, filteredItems)}
            </div>
        </div>
    );
};