import { Button, Row, Col, Divider, Avatar } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Person, Summary } from "./types";
import { Score } from "./Score";
import { HighLight } from "./HighLight";

type Props = {
  person: Person | null;
  summary: Summary;
  onBack: () => void;
  simple: boolean;
}

export const Details = ({person, summary, onBack, simple}: Props) => {
  if (person === null) {
    return null;
  }
  return (
    <div>
      <div className="details-background"></div>
      <Row className="details-container">
        {simple ? <Col span={3}/> : 
        <Col span={3} className="back-div">
            <div className="back-button" onClick={() => onBack()}>
              <img src="back.png" alt="" width={24}/>
            </div>
              <Row justify="end">
                <Col span={24}>
                  <div className="back-button-text" >
                    <p>Parliment</p>
                    <p>Debate</p>
                    <p>Database</p>
                  </div>
                </Col>
              </Row>          
        </Col>
        }
        <Col span={21} className="detail-body">
          {simple && <p className="back-mobile-button" onClick={() => onBack()}><ArrowLeftOutlined /> Home </p>}
          <div className="details-bio">            
            {!simple && 
              <p className={simple ? "name-simple" : "name"}> 
                {<p><Avatar src={<img src={person.image_link} alt="avatar" />} size={128}/></p>}
                {person.name} 
              </p>       
            }            
            {!simple && 
              <>
                <Row className="bio" justify="start">
                  <Col span={6} className="key"></Col>
                  <Col span={6} className="key">Caucus</Col>
                  <Col span={6} className="value">{person.caucus}</Col>
                  <Col span={6} className="key"></Col>
                </Row>
                <Row className="bio" justify="start">
                  <Col span={6} className="key"></Col>
                  <Col span={6} className="key">Constituency</Col>
                  <Col span={6} className="value">{person.area}</Col>
                  <Col span={6} className="key"></Col>
                </Row>
                <Row className="bio" justify="start">
                  <Col span={6} className="key"></Col>
                  <Col span={6} className="key">Province/Territory</Col>
                  <Col span={6} className="value">{person.province}</Col>
                  <Col span={6} className="key"></Col>
                </Row>      
                <p></p>
                <Row className="bio">
                  <Col span={6} className="key"></Col>
                  <Col span={6} className="key"><Button className="link-button" href={person.profile_link} target="_blank"><img width={12} src="profile_image.jpg" alt=""/> Profile</Button></Col>
                  <Col span={6} className="value"><Button className="link-button" href={summary.latest_viewpoint_link} target="_blank"><img width={12} src="viewpoint_img.jpg" alt=""/>Latest View Point</Button></Col>
                  <Col span={6} className="key"></Col>
                </Row>
              </>
            }      
          </div>
          <div>            
            <Row>
              <Col span={3}>
              </Col>
              <Col span={20}>
                {simple && 
                    <Row className="bio" align="middle">
                      <Col span={8} className="name-simple">{person.name}</Col>
                      <Col span={6} ><Button className="link-button" href={person.profile_link} target="_blank"><img width={12} src="profile_image.jpg" alt=""/> Profile</Button></Col>
                      <Col span={6} ><Button className="link-button" href={summary.latest_viewpoint_link} target="_blank"><img width={12} src="viewpoint_img.jpg" alt=""/>Latest View Point</Button></Col>
                    </Row>
                }
                <Row>
                  <Col span={6}>
                    <Score simple={simple} title="Consistency" score={summary.consistency_score} />                
                  </Col>
                  <Col span={6}>
                    <Score simple={simple} title="Sentiment" score={summary.sentiment_score} />
                  </Col>
                  <Col span={6}>
                    <Score simple={simple} title="Logic" score={summary.logic_score} />
                  </Col>
                  <Col span={6}>
                    <Score simple={simple} title="Objectiveness" score={summary.objectivity_score} divider={false} />
                  </Col> 
                </Row>
              </Col>
              <Col span={1}>
              </Col>                                                       
            </Row>
          </div>
          <div className={simple ? "summary-simple" : "summary"}>
            <Row>
              <Col span={3}/>
              <Col span={18}>
                <h2 className="summary-title">Summary</h2>
                <div className="summary-content">{summary.summary}</div>
              </Col>
              <Col span={3}/>
            </Row>
          </div>
          {!simple &&
            <div className="highlights">
            <Row>
                <Col span={3}/>
                <Col span={18}>
                  <div>                  
                    <h2 className="highlights-title"> <img width={16} src="highlights.png" /> &nbsp; HighLights </h2>
                  </div>                
                  {summary.highlights.split("\n").map(content => {
                    return (
                    <>
                      <HighLight content={content} />
                      <p></p>
                    </>
                    );
                  })}
                </Col>
                <Col span={3}/>
              </Row>                          
            </div>
          } 
        </Col>
      </Row>
    </div>
  );
}