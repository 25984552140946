import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AppMobile } from './AppMobile';

var mobile = require('is-mobile');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryParameters = new URLSearchParams(window.location.search);
const simple = queryParameters.get("simple");

root.render(
  <React.StrictMode>
    {
      mobile() ? <AppMobile simple={simple !== null} /> : <App simple={simple !== null} />      
    }
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
