import { Button, Row, Col, Divider, Avatar } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import './AppMobile.css';
import { Person, Summary } from "./types";
import { Score } from "./Score";
import { HighLight } from "./HighLight";
import {Radar} from "./Radar";
import React from "react";

type Props = {
  person: Person | null;
  summary: Summary;
  onBack: () => void;
  simple: boolean;
}

export const DetailsMobile = ({person, summary, onBack, simple}: Props) => {
  if (person === null) {
    return null;
  }
  return (
    <div className="details-mobile-container">
      <p className="back-mobile-button" onClick={() => onBack()}><ArrowLeftOutlined /> Home </p>      
        <div className="detail-mobile-body">
          {!simple && 
          <div className="details-mobile-bio">                          
            <p className="name"> 
              <p><Avatar src={<img src={person.image_link} alt="avatar" />} size={100}/></p>
              {person.name} 
            </p>                                
                <Row className="bio" justify="start">
                  <Col span={3} className="key"></Col>
                  <Col span={9} className="key">Caucus</Col>
                  <Col span={9} className="value">{person.caucus}</Col>
                  <Col span={3} className="key"></Col>
                </Row>
                <Row className="bio" justify="start">
                  <Col span={3} className="key"></Col>
                  <Col span={9} className="key">Constituency</Col>
                  <Col span={9} className="value">{person.area}</Col>
                  <Col span={3} className="key"></Col>
                </Row>
                <Row className="bio" justify="start">
                  <Col span={3} className="key"></Col>
                  <Col span={9} className="key">Province/Territory</Col>
                  <Col span={9} className="value">{person.province}</Col>
                  <Col span={3} className="key"></Col>
                </Row>      
                <p></p>
                <Row className="bio">
                  <Col span={2} className="key"></Col>
                  <Col span={10} className="key"><Button className="link-button" href={person.profile_link} target="_blank"><img width={12} src="profile_image.jpg" alt=""/> Profile</Button></Col>
                  <Col span={10} className="value"><Button className="link-button" href={summary.latest_viewpoint_link} target="_blank"><img width={12} src="viewpoint_img.jpg" alt=""/>Latest View Point</Button></Col>
                  <Col span={2} className="key"></Col>
                </Row>
            </div>       
          }             
          <div>
            <Row className="scores">
              {simple && 
                <div className="name-simple">
                <p>{person.name}</p>
                <Row className="bio">
                  <Col span={2} className="key"></Col>
                  <Col span={10} className="key"><Button className="link-button" href={person.profile_link} target="_blank"><img width={12} src="profile_image.jpg" alt=""/> Profile</Button></Col>
                  <Col span={10} className="value"><Button className="link-button" href={summary.latest_viewpoint_link} target="_blank"><img width={12} src="viewpoint_img.jpg" alt=""/>Latest View Point</Button></Col>
                  <Col span={2} className="key"></Col>
                </Row>
                </div>
              }
              <Col span={24}>
                <Row>
                  <Col span={4} className="key"></Col>
                  <Col span={8}>
                    <Score simple={simple} title="Consistency" score={summary.consistency_score} star={false} divider={false}/>                
                  </Col>
                  <Col span={8}>
                    <Score simple={simple} title="Sentiment" score={summary.sentiment_score} star={false} divider={false}/>
                  </Col>
                  <Col span={4} className="key"></Col>
                  <Col span={4} className="key"></Col>
                  <Col span={8}>
                    <Score simple={simple} title="Logic" score={summary.logic_score} star={false} divider={false}/>
                  </Col>
                  <Col span={8}>
                    <Score simple={simple} title="Objectiveness" score={summary.objectivity_score} divider={false} star={false}/>
                  </Col> 
                  <Col span={4} className="key"></Col>
                </Row>
              </Col>      
              <div className="score-border"></div>                                      
            </Row>
          </div>
          <div className="summary-mobile">
            <h2 className="summary-title">Summary</h2>
            <div className="summary-content">{summary.summary}</div>
          </div>
          {!simple &&
          <div className="highlights-mobile">
            <div>                  
              <h2 className="highlights-title"> <img width={16} src="highlights.png" alt="" className="mobile-highlight-icon"/> HighLights </h2>
            </div>                
            {summary.highlights.split("\n").map(content => {
              return (
              <>
                <HighLight content={content} mobile />
                <p></p>
              </>
              );
            })}               
        </div>
        }
      </div>      
    </div>
  );
}